//COLORS

$blue: #3e4962;
$red: #ed184f;

//GENERAL

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto';
  color: #333;
}

html,
body {
  height: 100%;
}
// SCROLL TO TOP BUTTON

.App-header {
  button {
    box-shadow: none;
    background-color: transparent;
    svg {
      font-size: 30px;
      color: $red;
    }
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;

  li {
    list-style-type: none;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        border-bottom: 3px solid $red;
        transition: all 100ms;
        padding: 0 0 3px 0;
      }
    }
  }
}

//LANDING PAGE

.landing-container {
  margin: 6% 20% 8% 12%;

  h1 {
    font-family: 'Rubik', sans-serif;
    -webkit-text-stroke: 2px #333;
    color: white;
    font-size: 8vw;
    font-variant-caps: normal;
    text-transform: uppercase;
  }
  h3 {
    font-size: 3vw;
  }
  h4 {
    font-size: 1.2rem;
    letter-spacing: 1.2rem;
    text-transform: uppercase;
    color: $red;
    margin: 8% 0 2% 0;
  }
  .about-me {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    img {
      margin: -18% -15% 0px 0%;
    }
    p {
      text-align: justify;
      line-height: 1.4rem;
    }
    a {
      color: $red;
      text-decoration: none;
    }
  }
  .find-me {
    .social-media {
      display: flex;
      width: 40%;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
      .LinkedIn {
        svg {
          font-size: 50px;
          color: #0276b5;
        }
      }
      .GitHub {
        svg {
          font-size: 55px;
          color: #24292d;
        }
      }
      .Email {
        svg {
          font-size: 55px;
          color: #bf211e;
        }
      }
    }
  }
}

//Portfolio Page
#Portfolio {
  background-color: black;
  padding: 2% 0;
  .portfolio-container {
    margin: 3% 20% 3% 12%;

    .portfolio-header {
      h1 {
        font-family: 'Rubik', sans-serif;
        -webkit-text-stroke: 2px white;
        color: black;
        font-size: 4vw;
        font-variant-caps: normal;
        text-transform: uppercase;
      }
    }
    .DreamHome-container {
      margin-top: 5%;
      display: flex;
      justify-content: flex-end;

      .DreamHome-text {
        h4 {
          color: $red;
          text-transform: uppercase;
          letter-spacing: 1.2rem;
          margin: 8% 0 2% 0;
          font-size: 1.2rem;
        }
        p {
          color: white;
          margin: 10% 0;
        }
      }
      .DreamHome-image {
        img {
          width: 100%;
          min-width: 700px;
          margin-left: 15%;
        }
      }
    }

    .go-to-github {
      margin-top: 4%;

      p {
        color: white;

        a {
          color: $red;
          text-decoration: none;
        }
      }
    }
  }
}

// SKILLS

#Technologies {
  margin: 6% 20% 0 12%;
  h1 {
    font-family: 'Rubik', sans-serif;
    -webkit-text-stroke: 2px #333;
    color: white;
    font-size: 4vw;
    font-variant-caps: normal;
    text-transform: uppercase;
  }
  p {
    margin: 3% 0;
  }
  h4 {
    font-size: 1.2rem;
    letter-spacing: 1.2rem;
    text-transform: uppercase;
    color: $red;
    margin: 5% 0 2% 0;
  }
  section {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    span {
      margin: 0.1%;
      img {
        max-height: 75px;
        margin: 30px;
      }
    }
  }
}

.software {
  margin: 6% 20% 0 12%;
  section {
    h1 {
      font-family: 'Rubik', sans-serif;
      -webkit-text-stroke: 2px #333;
      color: white;
      font-size: 4vw;
      font-variant-caps: normal;
      text-transform: uppercase;
    }
    ul {
      margin: 3% 0 6% 3%;
      li {
        list-style-type: none;
        padding-right: 20px;
        &:before {
          content: '📌';
          font-size: 20px;
          display: inline-block;
          margin-right: 1.2rem;
        }
      }
    }
    h4 {
      font-size: 1.2rem;
      letter-spacing: 1.2rem;
      text-transform: uppercase;
      color: $red;
      margin: 5% 0 2% 0;
    }
  }
}

.methodology {
  h5 {
    font-size: 1.3rem;
    margin-bottom: 2%;
  }
  div:nth-child(2n) {
    width: 60%;
    margin-bottom: 5%;
  }
  div:nth-child(3n) {
    width: 60%;
    margin-left: 40%;
    text-align: right;
  }
}

// CONTACT

#Contact {
  background-color: black;
  color: #ffffff;

  section {
    margin: 6% 20% 0 12%;
    padding-bottom: 5%;
    h1 {
      padding-top: 5%;
      font-size: 4vw;
      font-family: 'Rubik', sans-serif;
      -webkit-text-stroke: 2px white;
      color: black;
      font-variant-caps: normal;
      text-transform: uppercase;
    }
    h4 {
      font-size: 1.2rem;
      letter-spacing: 1.2rem;
      text-transform: uppercase;
      color: #ed184f;
      margin: 5% 0 2% 0;
    }
    form {
      margin-top: 2%;
      justify-content: center;
      width: 40%;
      display: flex;
      flex-direction: column;
      input {
        margin-bottom: 2%;
        min-height: 35px;
        padding: 2%;
        border-radius: 5px;
        outline: none;
      }
      textarea {
        outline: none;
        padding: 1%;
        font-family: inherit;
        border-radius: 5px;
        min-height: 100px;
        resize: vertical;
        margin-top: 1%;
        margin-bottom: 4%;
      }

      .submit-button {
        width: 70%;
        display: block;
        margin: 1% auto;
        min-height: 35px;
        background-color: $red;
        border: none;
        color: white;
        border-radius: 20px;
        outline: none;
        font-size: 1.2rem;
      }
    }
  }
}

.float {
  float: right;
  overflow: hidden;
  clear: both;
  max-width: 600px;
  width: 100%;
  margin: 10% 5% 0 0;
}

// RESPONSIVE DESIGN

@media only screen and (max-width: 1170px) {
  .DreamHome-image img {
    min-width: 400px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .float {
    max-width: 500px;
  }
  .landing-container .about-me img {
    width: 100%;
    max-width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  #Portfolio .portfolio-container .DreamHome-container {
    flex-direction: column;
    margin-left: 0;
    justify-content: center;
  }
  #Portfolio .portfolio-container .DreamHome-container .DreamHome-text p {
    margin: 3% 0;
  }
  #Portfolio .portfolio-container .DreamHome-container .DreamHome-image img {
    margin-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .float {
    max-width: 400px;
  }
}

@media only screen and (max-width: 800px) {
  .float {
    max-width: 350px;
  }
  .landing-container .about-me {
    flex-direction: column;
    img {
      display: block;
      margin: 3% auto;
    }
  }
}
@media only screen and (max-width: 768px) {
  .mainNav {
    border: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .float {
    max-width: 300px;
    margin-top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .float {
    display: none;
  }
  #Contact {
    section {
      form {
        margin: 6% auto 0 auto;
        padding-bottom: 5%;
        width: 80%;
      }
      h1 {
        text-align: center;
      }
      h4 {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  h4 {
    margin: 10% 0 5% 0 !important;
    letter-spacing: 1rem !important;
    font-size: 0.9rem;
  }
  .social-media {
    width: 100% !important;
    margin: 10% 0;
  }
  .landing-container {
    margin: 6% 14% 8% 14%;
    h1 {
      font-size: 9vw;
      text-align: center;
    }
    h3 {
      font-size: 4vw;
      text-align: center;
    }
    h4 {
      text-align: center;
      line-height: 1.8rem;
    }
  }
  .DreamHome-image img {
    display: none;
  }
  .DreamHome-text {
    p {
      text-align: justify;
      margin: 6% 0 !important;
    }
  }
  .portfolio-header,
  #Technologies {
    h1 {
      text-align: center;
      font-size: 8vw !important;
    }
  }
  .software {
    section {
      margin-bottom: 15%;
    }
  }
  .methodology {
    div {
      width: 100% !important;
      margin-left: 0 !important;
      margin-top: 10% !important;
      p,
      h5 {
        text-align: justify;
      }
      h5 {
        color: $blue;
      }
    }
  }
  #Contact {
    section {
      h1 {
        text-align: center;
        font-size: 8vw;
        margin-top: 3%;
        padding-top: 10%;
      }
      h4 {
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 415px) {
  .methodology {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  h4 {
    font-size: 0.9rem !important;
  }
  .Technologies section span img {
    max-height: 68px;
    margin: 25px;
  }
}

@media only screen and (max-width: 320px) {
  .landing-container {
    margin: 6% 18% 8% 18%;
  }
}
